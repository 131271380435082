<template>
  <div>
    <div class="no-internet">
      <div class="intenet-img">
        <img src="../assets/right-answer-illustration.png" />
      </div>

      <div class="right-ans">
        <h3>Hurray!</h3>
        <h4>Right Answer</h4>
        <h5>You won</h5>
        <h6>{{ reword_point }} Coins</h6>
        <p>
          Comeback tomorrow for a new question to win points and lots of
          surprize!
        </p>
      </div>

      <!--        <div class="no-inter-btn"> <a href="/"> Continue</a></div>-->
      <router-link to="/" tag="button" class="submit-btn4"
        >Continue</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "RightAnswer",
  props: ["reword_point"]
};
</script>

<style scoped></style>
