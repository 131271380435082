<template>
  <div>
    <QuizOwner v-if="quizOwner" />
    <div id="errorMessage" style="color:red;display: none">&nbsp;</div>
    <component
      :is="mode"
      v-if="!loading"
      :answerList="answerList"
      :question="question"
      :reword_point="reword_point"
      :hideShow="saveButton"
      :validAnswer="validAnswer"
      @answerCheck="answerChecker($event)"
    >
    </component>
  </div>
</template>

<script>
import axios from "axios";
import Question from "@/components/Question.vue";
import RightAnswer from "@/components/RightAnswer.vue";
import WrongAnswer from "@/components/WrongAnswer.vue";
import QuizOwner from "@/components/QuizOwner.vue";

export default {
  name: "Quiz",
  components: {
    Question,
    RightAnswer,
    WrongAnswer,
    QuizOwner
  },
  data: () => {
    return {
      question: "",
      answerList: [],
      quizOwner: false,
      id: 0,
      reword_point: 0,
      loading: true,
      saveButton: true,
      phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : "",
      mode: "Question",
      validAnswer: null,
      host: null
    };
  },
  created() {
    this.host = this.$root.url();
    this.quiz(this.phone);
  },
  methods: {
    async answerChecker(data1) {
      try {
        this.saveButton = data1.hideShow;
        if (this.phone === "") {
          alert("Please login XoshPlay.com");
          this.$router.push({ name: "Registration" });
        }
        const path = "quizAnswer";
        const url = `${this.host}/${path}?phone=${this.phone}&answer=${data1.answer}&quizId=${this.id}`;
        let response = await axios.get(url);
        console.log(response);
        let success = response.data.success;
        if (success) {
          if (response.data.quizStatus) {
            this.mode = "RightAnswer";
          } else {
            this.mode = "WrongAnswer";
            this.validAnswer = response.data.validAnswer;
          }
        } else {
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
          this.saveButton = true;
          $("#errorMessage")
            .show()
            .text(response.data.message);
        }
      } catch (e) {
        this.saveButton = true;
        $("#errorMessage")
          .show()
          .text("Something wrong, please try again later");
      }
    },
    async quiz(phone = null) {
      try {
        const path = "quizQuestion";
        let url = "";
        if (phone) {
          url = `${this.host}/${path}?phone=${phone}`;
        } else {
          url = `${this.host}/${path}`;
        }
        let response = await axios.get(url);
        let success = response.data.success;
        if (success) {
          this.answerList = JSON.parse(
            response.data.quizDetails.question_options
          );
          this.id = response.data.quizDetails.question_id;
          this.reword_point = response.data.quizDetails.reword_point;
          this.question = response.data.quizDetails.question_text;
          this.loading = false;
        } else {
          if (response.data.code === 3004) {
            localStorage.clear();
            this.$router.push({ name: "Registration" });
          }
          $("#errorMessage")
            .show()
            .text(response.data.message);
        }
      } catch (e) {
        $("#errorMessage")
          .show()
          .text("Something wrong, please try again later");
      }
    }
  }
};
</script>

<style scoped></style>
