<template>
  <div>
    <div class="no-internet">
      <div class="intenet-img">
        <img src="../assets/wrong-answer-illustration.png" />
      </div>

      <div class="no-inter-texts">
        <h3>Opps!</h3>
        <h4>Wrong Answer</h4>
        <h3>
          Right answer was : &nbsp;&nbsp;<strong v-text="validAnswer"></strong>
        </h3>
        <p>
          Comeback tomorrow for a new question to win points and lots of
          surprize!
        </p>
      </div>
      <router-link to="/" tag="button" class="submit-btn4"
        >Continue</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "WrongAnswer",
  props: ["wrong", "validAnswer"]
};
</script>

<style scoped></style>
