<template>
  <div class="update3-news">
    <div class="update-01">Update</div>
    <div class="update-text-01">Arman haque has won a tournament of $100</div>
  </div>
</template>

<script>
export default {
  name: "QuizOwner"
};
</script>

<style scoped></style>
