<template>
  <div>
    <div class="today-ques-01">
      <div class="intenet-img">
        <img src="../assets/today-question-banner.png" />
      </div>

      <div class="todays-ques-02">
        <p>If you want to play and win, try today's question below.</p>
        <h6>Today's question</h6>
      </div>

      <div class="questions-area">
        <div class="questions-01">
          <h3>{{ question }}</h3>
        </div>
        <div class="question-options">
          <form class="qes-op-01">
            <div
              class="inputGroup"
              v-for="(item, index) in answerList"
              :key="item"
            >
              <input
                :id="'radio' + index + 1"
                v-model="answer"
                type="radio"
                :value="answerList[index]"
              />
              <label :for="'radio' + index + 1">{{ item }}</label>
            </div>
          </form>
        </div>
      </div>
      <div class="see-answer">
        <button
          type="button"
          @click="userResponse"
          :disabled="disabled"
          v-if="hideShow"
        >
          See the answer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question",
  props: ["question", "answerList", "hideShow"],
  data: () => {
    return {
      answer: "",
      disabled: true
    };
  },
  methods: {
    userResponse() {
      this.$emit("answerCheck", { answer: this.answer, hideShow: false });
    }
  },
  watch: {
    answer: function(val) {
      this.disabled = val !== "" ? false : true;
    }
  }
};
</script>

<style scoped></style>
